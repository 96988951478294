// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` or `ng build --prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  application_name: 'Recovery Inventory Management',
  isLocal: false,
  isTest: true,
  production: true,
  navigationMode: 'top',
  tokenCacheTimeout: 60 * 60 * 1000,
  notification: {
    position: 'relative',
    messageRole: 'alertdialog',
    closeButton: true,
    animationType: 'fadeAnimation',
    autoClose: true,
    messageVisibleTime: '5000',
    animationTime: '.5'
  },
  oauth: {
    issuer: 'https://ssoiamcorp-nonprod.uhg.com',
    loginUrl: 'https://ssoiamcorp-nonprod.uhg.com/as/authorization.oauth2',
    logoutUrl: 'https://ssoiamcorp-nonprod.uhg.com/idp/startSLO.ping',
    redirectUri: 'https://rim-dev.optum.com',
    silentRefreshUri: 'https://rim-dev.optum.com/silent-refresh.html',
    clientId: 'RIM_DEV',
    responseType: 'id_token token',
    scope: 'openid profile address email phone',
    acr_values: 'AAL1_MS-AD-Kerberos',
    autoRefresh: true
  },
  apiBaseUrl: 'https://odar-api-dev.optum.com/rim',
  rimEndPoint: '/api/paymentintegrity/recovery/rim/v1.0',
  localMockDataUrl: './assets/mockdata',
  emdsUrl: 'https://edms-idrs-ui-dev.uhc.com/idrs/viewer?',
  idleTimeout: 900,
  matomoId: 72,
  useDoc360: true,
  unifiedUiRedirectFlag: true,
  unifiedUiUrl: "https://reco-dev.optum.com",
  recoServicesUrl: 'https://reco-api-dev.optum.com',
  azureClientId: '6b3b8d49-6e6c-434c-869b-b64443f3772b',
  env: 'dev'
};
