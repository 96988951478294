import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './shared/services/auth.service';
import { environment } from '../environments/environment';

import { OAuthService, OAuthEvent, NullValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from './shared/models/auth.config';
import { UserProfileService } from './shared/services/user-profile.service';
import { TrackingService } from './shared/services/tracking.service';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode } from 'jwt-decode';
import { CacheService } from './shared/services/cache.service';
import { HttpClient } from '@angular/common/http';
// import { TimerObservable } from "rxjs-compat/observable/TimerObservable";
import { firstValueFrom, timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';  


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'recovery-rim-ui';
  user: any;
  isAuthenticated: boolean = false;
  isAuthenticatedForAdmin: boolean = false;
  year = new Date().getFullYear();
  navigationMode: string | undefined;
  loggedIn = false;
  showTopNav = false;
  showSideNav = false;
  public applicationName: string | undefined;
  idleState: string | undefined;
  initialiseMatomo = true;
  myFooterText: string = `Your use of this product is governed by the terms of your company's agreement. You may not use or disclose this product
  or allow others to use it or disclose it, except as permitted by your agreement with Optum.`;
  alertMessage = {
    content: '',
  };

  path = "assets/images/optum-logos/Optum_logo_header.svg";
  alt = 'optum logo';
  public showAssign: boolean;
  @Output() onClosePopup = new EventEmitter()

  readonly recoveryCookieName = 'recovery.authentication';
  public baseUrl: string = window.location.origin;
  readonly recoveryRefreshTokenCacheTime = 7.5 * 60 * 60 * 1000; // 7.5 Hours
  readonly accessTokenCacheTime = 29.5 * 60 * 1000; // 29.5 minutes
  readonly idTokenCacheTime = 20 * 60 * 1000; // 20 minutes

  ngOnInit(): void {
    this.navigationMode = environment.navigationMode;
    this.applicationName = environment.application_name;
    this.InitializeAuthentication();
  }

  constructor(
    private authService: AuthService,
    private readonly http: HttpClient,
    private oauthService: OAuthService,
    private userProfileSvc: UserProfileService,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private trackingService: TrackingService,
    private readonly cookieService: CookieService,
    private readonly cacheService: CacheService
  ) {

    // how many seconds of inactivity before a user is considered idle
    this.idle.setIdle(1500);

    // after a user goes idle, set how many seconds until the user will be considered timed out.
    this.idle.setTimeout(environment.idleTimeout);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.showAssign = false;
      this.setLoggedOut();
      this.router.navigate(['logout']);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      this.showAssign = true;
    });
    // sets the ping interval
    this.keepalive.interval(1500);
    // this can be used to perform an action that user is still active [if needed]
    this.keepalive.onPing.subscribe(() => { });
  }

  private async InitializeAuthentication() {
    this.showSideNav = false;

    this.authService.authEvent.subscribe(user => {
      if (user) {
        this.user = user;
        this.addUserProfile();
        if (this.authService.isAuthenticated()) {
          this.isAuthenticated = true;
          this.setLoggedIn(this.authService.user);
        }
        else {
          this.router.navigate(['/unauthorized']);
        }
      } else {
        this.setLoggedOut();
      }
    });

    // if (environment.unifiedUiRedirectFlag) {
    //   this.initializeUnifiedUiToken();
    //   await this.refresh();
    //   // check every 1m
    //   TimerObservable.create(0, 15 * 60 * 1000).subscribe(async () => {
    //     await this.refresh();
    //   });

    // } else {
    //   this.configureOAuth();
    //   this.setLoginStatus();
    // }

  if (environment.unifiedUiRedirectFlag) {
      this.initializeUnifiedUiToken();
      await this.refresh();
      // check every 15 minutes
      setInterval(async () => {
        await this.refresh();
      }, 15 * 60 * 1000);
    } else {
      this.configureOAuth();
      this.setLoginStatus();
    } 
  }
  private configureOAuth() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.silentRefreshRedirectUri = window.location.origin + '/silent-refresh.html';
    if (environment.oauth.autoRefresh) {
      this.oauthService.tryLogin().then(() => this.oauthService.setupAutomaticSilentRefresh());
    } else {
      this.oauthService.tryLogin();
    }

    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      if (type === 'token_expires') {
        this.initialiseMatomo = false;
      }
      if (type === 'token_received' && this.oauthService.hasValidAccessToken()) {
        this.authService.setUser(this.oauthService.getIdentityClaims());
        if (!this.loggedIn) {
          this.setLoginStatus();
        }
      }
    });
    this.trackingService.trackRouterEvents();
  }

  setLoginStatus() {
    if (environment.isLocal) {
      this.authService.login();
      this.setLoggedIn(this.authService.user);
      this.addUserProfile();
    }
    else {
      if (!this.oauthService.hasValidAccessToken()) {
        this.router.navigate(['/login']);
      }
      else {
        this.user = this.oauthService.getIdentityClaims();
        this.authService.setUser(this.user);
      }
    }
  }

  setLoggedIn(user: any) {
    this.isAuthenticatedForAdmin = this.authService.isAuthenticatedForAdmin();
    this.user = user;
    this.loggedIn = true;
    this.startIdle();
  }

  setLoggedOut() {
    this.isAuthenticated = false;
    this.loggedIn = false;
    this.initialiseMatomo = true;
  }

  setUserLink(link: any, user: any) {
    link.textTemplate = `<span> ${user.given_name} ${user.family_name} </span>`;
  }


  startIdle() {
    this.idle.watch();
    this.idleState = 'Started.';
  }
  addUserProfile() {
    this.userProfileSvc.addUserProfile().subscribe({
      next: x => { },
      error: error => { },
    });


  }


  initializeUnifiedUiToken() {
    const recoveryAuthCookieString = this.cookieService.get(this.recoveryCookieName)
    const recoveryAuthCookie = recoveryAuthCookieString
      ? JSON.parse(recoveryAuthCookieString)
      : { refreshToken: '', accessToken: '', authProvider: '' };
    const { refreshToken, accessToken, authProvider } = recoveryAuthCookie;
    const decodedAccessToken = accessToken ? jwtDecode<any>(accessToken) : "";
    if (!refreshToken || authProvider !== 'azure' || decodedAccessToken?.appid !== environment.azureClientId) {
      window.location.href = `${environment.unifiedUiUrl}/login?kind=azure&returnUrl=${encodeURIComponent(this.baseUrl)}`;
    } else {
      this.cacheService.set('refreshToken', refreshToken, this.recoveryRefreshTokenCacheTime);
      this.cacheService.set('access_token', accessToken, this.recoveryRefreshTokenCacheTime);
    }
  }

  async refresh() {
    const cacheRefreshToken  = this.cacheService.get('refreshToken');
    const accessToken = this.cacheService.get('access_token');
    const decodedAccessToken = accessToken ? jwtDecode<any>(accessToken) : "";
    if (!cacheRefreshToken  || (decodedAccessToken?.appid !== environment.azureClientId)) {
      window.location.href = `${environment.unifiedUiUrl}/login?kind=azure&returnUrl=${encodeURIComponent(this.baseUrl)}`;
    } else {
      // const response = await this.http.post(`${environment.recoServicesUrl}/auth/refresh?authProvider=azure`, { refresh: refreshToken }).toPromise()
      const response = await firstValueFrom(this.http.post(`${environment.recoServicesUrl}/auth/v2/refresh`, {authProvider:'azure', refreshToken: cacheRefreshToken  }));
      const { refreshToken, idToken, accessToken, user } = response as any
      this.cacheService.set('refreshToken', refreshToken, this.recoveryRefreshTokenCacheTime);
      this.cacheService.set('id_token', idToken, this.idTokenCacheTime);
      this.cacheService.set('access_token', accessToken, this.accessTokenCacheTime);
      const domain = window.location.origin.includes('localhost') ? 'localhost' : 'optum.com'
      this.cookieService.set(
        this.recoveryCookieName,
        JSON.stringify({ refreshToken: refreshToken, accessToken: accessToken, authProvider: 'azure' }),
        {
          expires: 1, // days
          sameSite: 'Lax',
          secure: true,
          path: '/',
          domain: domain
        }
      )
      await this.authService.setAzureUser(user);
    }
  }

  public getRouterLink() {
    return this.isAuthenticated ? '/logout' : '/login'
  }

  logout() {
    this.showAssign = false;
    this.router.navigate(['logout']);
  }

  closeDialog() {
    this.showAssign = false;
    this.onClosePopup.emit();
  }
}
