import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of, concat } from 'rxjs';
import { reduce } from 'rxjs/operators'
import { DocumentService } from './document.service';

@Injectable({  providedIn: 'root',})// This service is available in root
export class EdmsService extends DocumentService {
  private apiUrl: string = environment.apiBaseUrl + environment.rimEndPoint;
  private endPoint = 'edms';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getQueryString(fileLocationNumber: string): Observable<string> {
    return this.httpClient.get(`${this.apiUrl}/${this.endPoint}/${fileLocationNumber}`,
      { responseType: 'text' });
  }

  public getUrl(fileLocationNumber: string): Observable<string> {
    let f = concat(of(environment.emdsUrl), this.httpClient.get(`${this.apiUrl}/${this.endPoint}/${fileLocationNumber}`,
    { responseType: 'text' }));
    return f.pipe(reduce((acc, one) => acc + one));
  }

}
