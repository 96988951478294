import { Component } from '@angular/core';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  loaderText: string = 'Loading...';
  isloaderVisible: boolean = true;

  constructor(loaderService: LoaderService) {
    let text = loaderService.getLoaderText();
    if (text) {
      this.loaderText = text;
    }
    this.isloaderVisible = loaderService.isLoaderVisible();
  }

}
