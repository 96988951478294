<p-dialog [(visible)]="showAssign" [draggable]="true" [resizable]="true" [showHeader]="false" [modal]="true"  
  (onHide)="closeDialog()" styleClass="mydialog">
  <div class="grid">
    <div class="col-12">
      <h4 id="dialog-child-name" class="modal-title pull-left">You Have Been Idle!</h4>
      <div class="modal-body">
        {{idleState}}
        <br>
        <h6>{{this.alertMessage.content}}</h6>
      </div>
      <div class="col-12">
        <p-button id="cancelButton" class="pad-right-small" label="Logout" type="button" (click)="logout()">
        </p-button>
        &nbsp;&nbsp;
        <p-button id="cancelButton" class="pad-right-small" label="Stay" type="button" (click)="closeDialog()">
        </p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-menubar id="user-menu" class='app-global-nav'>
  <ul class="p-menubar-root-list">
    <em class="pi pi-user"></em>
    <li *ngIf="this.isAuthenticated" class="p-menuitem">
      <a id="user-name" class="p-ripple p-menuitem-link">
        Welcome, {{this.user.fullName}}
      </a>
    </li>
    <li class="p-menuitem">
      <a id="authentication-link" [routerLink]="getRouterLink()" class="p-ripple p-menuitem-link"
        style="text-decoration: underline;">
        {{this.isAuthenticated ? 'Logout':'Login'}}
      </a>
    </li>
  </ul>
</p-menubar>

<div id="nav-panel" class="primary-nav">
  <div class="primary-nav-logo-container" style="padding: 10px">
    <img [src]="path" alt="optum" class="primary-nav-logo">
  </div>
  <ul class="primary-nav-root-container">

    <li class="primary-nav-root-item" *ngIf="this.isAuthenticated" routerLink="/home">
      <a>Home</a>
    </li>
    <li *ngIf="this.isAuthenticated" class="primary-nav-root-item" routerLink="/rim/inventory">
      <a>Inventory</a>
    </li>
    <li *ngIf="this.isAuthenticated && this.isAuthenticatedForAdmin" class="primary-nav-root-item"
      routerLink="/rim/administration">
      <a>Administration</a>
    </li>
  </ul>
</div>

<main id="main-content" class="starter-wrapper" tabindex="-1" style="outline: none;">
  <router-outlet></router-outlet>
</main>
<footer class="footer">
  <p>
    &copy;<span id="demo-year">{{year}}</span> Optum, Inc. All rights reserved.
    Your use of this product is governed by the terms of your company's
    agreement. You may not use or disclose this product or allow others
    to use it or disclose it, except as permitted by your agreement with Optum.
  </p>
</footer>