import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './account/login/login.component';
import { LogoutComponent } from './account/logout/logout.component';
import { UnauthorizedComponent } from './account/unauthorized/unauthorized.component';

const routes: Routes = [ { path: 'login', component: LoginComponent },
{ path: 'logout', component: LogoutComponent },
{ path: 'home', component: HomeComponent },
{ path: 'rim', 
loadChildren: () => import('./rim/rim.module').then(m => m.RimModule),
 },
{ path: 'unauthorized', component: UnauthorizedComponent },
{ path: '**', pathMatch: 'full', redirectTo: '/home' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
