import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  counter: number | undefined;
  timerRef:any;

  startTimer() {
    const startTime = Date.now() - (this.counter || 0);
    this.timerRef = setInterval(() => {
      this.counter = Date.now() - startTime;
    });
  }

  stopTimer() {
    const counter = this.counter;
    this.counter = undefined;
    clearInterval(this.timerRef);
    return counter;
  }
}