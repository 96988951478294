import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface CacheContent {
  expiry: number;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private readonly DEFAULT_MAX_AGE: number = environment.tokenCacheTimeout;

  get(key: string): any {
    const i = localStorage.getItem(key);
    if (i == null) {
      return i;
    } else {
      const parsed = JSON.parse(i);
      if (parsed.expiry != undefined) {
        if (parsed.expiry < Date.now()) {
          localStorage.removeItem(key);
          return undefined;
        } else {
          return parsed.value;
        }
      }
      else {
        return parsed;
      }
    }
  }

  set(key: string, val: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
    const item: CacheContent = { expiry: Date.now() + maxAge, value: val };
    localStorage.setItem(key, JSON.stringify(item));
  }
}
