// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { CacheService } from '../services/cache.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public oauthService: OAuthService,
              public readonly cacheService: CacheService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (environment.unifiedUiRedirectFlag) {
      const token = this.cacheService.get('id_token');
      const isApiUrl = request.url.includes(environment.apiBaseUrl)
        || request.url.includes(environment.recoServicesUrl);
      if (token && isApiUrl) {
        request = request.clone({
          setHeaders: {
            'recovery-authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
      }
    }
    else {
      const token = this.oauthService.getIdToken();
      if (token && (request.url.includes(environment.apiBaseUrl))) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
          }
        });
      }
    }
    
    return next.handle(request);
  }
}
