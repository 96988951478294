import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { DocumentService } from './document.service';

@Injectable()
export class Doc360DocumentService extends DocumentService {
  private apiUrl: string = environment.apiBaseUrl + environment.rimEndPoint;
  private endPoint = 'documents';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getUrl(fileLocationNumber: string): Observable<string> {
    return this.httpClient.get(`${this.apiUrl}/${this.endPoint}/${fileLocationNumber}`,
      { responseType: 'text' });
  }
}
