import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TitleCasePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private readonly previousUrl = 'previous-url';

  constructor( private readonly router: Router,
     private readonly titlecasePipe: TitleCasePipe) {}

  trackRouterEvents() {
    this.router.events.subscribe((event) => {
      let previousUrl;
      if (event instanceof NavigationStart) {
        previousUrl = this.router.url;
        if (previousUrl) {
          sessionStorage.setItem(this.previousUrl, previousUrl);
        }
      }
    });
  }
}