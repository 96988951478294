import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  url: string;
  constructor(private httpClient: HttpClient) {
    this.url = environment.apiBaseUrl + environment.rimEndPoint + '/user';
  }
  public addUserProfile(): Observable<any> {
    return this.httpClient.post(this.url, '');
  }

}
