import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, of } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { LocationService } from './location.service';
import { CacheService } from './cache.service';

@Injectable()
export class AuthService {
  USER_KEY = 'user';
  authEvent = new Subject<any>();
  user: any = {};
  mockUser = {
    'sub': 'isuser',
    //'givenName': 'Test',
    'roles': [
      'RIM_MailLead_Dev'
    ],
    'name': 'asoni14',
    //'familyName': 'User',
    'fullName' : 'test User',
    'email': 'test.user@optum.com',
    'aud': 'uitk_dev',
    'jti': 'gCGQSe8hydROoabrRUU1S4',
    'iss': 'https://ssoiamcorp-nonprod.uhg.com',
    'iat': 1538657433,
    'exp': 1538652886,
    'pi.sri': 'cHWY5hzU-3Bo9lmN380fiz3XzzU',
    'nonce': 'thisisanonce',
    'acr': 'AAL1_MS-AD-Kerberos',
    'auth_time': 1538657433,
    'at_hash': 'xtvix87Pp6PBX9aWbXt5lA'
  };

  constructor(private router: Router, private oauthService: OAuthService, private locationService: LocationService, private readonly cacheService: CacheService) {
    this.getUserFromLocalStorage();
  }

  public get location() {
    return location;
  }

  public getUserFromLocalStorage(){
    this.user = this.cacheService.get(this.USER_KEY);
  }

  setUser(user: any) {
    this.user = Object.assign(user, this.user);
    localStorage.setItem(this.USER_KEY, JSON.stringify(this.user));
    this.authEvent.next(this.user);
  }

  public async setAzureUser(user) {
    // Map it to the existing user
    const msId = user.username; //user.secondaryEmails.filter(email => email.includes("@uhgazure.mail.onmicrosoft.com")).map(email => email.split("@")[0])[0];
    this.user = {
      userName: msId,
      consumerName: msId,
      name: msId,
      fullName :user.name,
      //givenName: user.givenName,
      //familyName: user.familyName,
      roles: user.roles // e.g. [RIM_MailLead_Test]
    }

    localStorage.setItem(this.USER_KEY, JSON.stringify(this.user));
    this.authEvent.next(this.user);
  }

  login() {
    if (environment.isLocal) {
      this.user = Object.assign(this.mockUser, this.user);
      localStorage.setItem(this.USER_KEY, JSON.stringify(this.user))
      this.authEvent.next(this.user);

    } else if (!environment.unifiedUiRedirectFlag) {
      this.oauthService.initLoginFlow();
    }
    else if (!this.cacheService.get("refreshToken")) {
      window.location.href = `${environment.unifiedUiUrl}/v2/login?kind=azure&returnUrl=${encodeURIComponent(window.location.origin)}`;
    }
    else {
      this.router.navigate(['/home']);
    }
  }

  logout() {
    this.clearSession();
    this.router.navigate(['/logout']);
  }

  isAuthenticated(): boolean {
    try {
      return this.userIsMailAnalyst() || this.userIsMailLead() || this.userIsReadOnly();
    } catch {
      return false;
    }
  }

  isAuthenticatedForWrite(): boolean {
    return this.userIsMailAnalyst() || this.userIsMailLead();
  }

  isAuthenticatedForAdmin(): boolean {
    return this.userIsMailLead();
  }

  getUserRoles(): string[] {
    if (this.user) {
      return this.user.roles;
    }
    return [];
  }

  current() {
    if (this.user && this.user.userName) {
      return of(this.user);
    } else {
      return of(null);
    }
  }

  clearSession() {
    this.user = null;
    if (environment.unifiedUiRedirectFlag) {
      localStorage.clear();
    }
    else {
      this.oauthService.logOut();
    }
    this.authEvent.next(null);
  }

  userIsMailLead(): boolean {
    let role: string = 'RIM_MailLead_' + this.getEnvironment();
    return this.checkAzureRoles(role) || this.checkMSADGroups(role);
  }
  userIsMailAnalyst(): boolean {
    let role: string = 'RIM_MailAnalyst_' + this.getEnvironment();
    return this.checkAzureRoles(role) || this.checkMSADGroups(role);
  }
  userIsReadOnly(): boolean {
    let role: string = 'RIM_ReadOnly_' + this.getEnvironment();
    return this.checkAzureRoles(role) || this.checkMSADGroups(role);
  }

  checkAzureRoles(azureRoleName): boolean {
    return this.user && this.user.roles && (this.user.roles.find(role => role.includes(azureRoleName)) === undefined ? false : true);
  }

  checkMSADGroups(msadGroupName): boolean {
    return this.user && this.user.msad_groups && (this.user.msad_groups.find(msad_group => msad_group.includes(msadGroupName)) === undefined ? false : true);
  }

  getEnvironment(): string {
    if (environment.env === "test") {
      return "Test";
    }
    else if (environment.env === "stage") {
      return "Stage";
    }
    else if (environment.env === "prod") {
      return "Prod";
    }
    else {
      return "Dev";
    }
  }
}
