import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { defer, NEVER, Subject } from 'rxjs';
import { finalize, share } from 'rxjs/operators';
import { LoaderComponent } from 'src/app/loader/loader.component';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private overlayRef?: OverlayRef;
  isLoading = new Subject<boolean>();
  private loaderText: string = "Loading....";
  private isloaderVisible: boolean;

  constructor(private injector: Injector) { }

  public show(): void {
    this.isLoading.next(true);
    let overlay = this.injector.get(Overlay);
    Promise.resolve(null).then(() => {
      this.overlayRef = overlay.create({
        positionStrategy: overlay.position().global().centerHorizontally().centerVertically(),
        hasBackdrop: false,
      });
      this.overlayRef.attach(new ComponentPortal(LoaderComponent));
    });
  }

  public readonly spinner$ = defer(() => {
    this.show();
    return NEVER.pipe(finalize(() => this.hide()));
  }).pipe(share());

  public hide(): void {
    this.isLoading.next(false);
    this.overlayRef!.detach();
    this.overlayRef = undefined;
  }

  public getLoaderText(): string {
    return this.loaderText;
  }

  public isLoaderVisible(): boolean {
    return this.isloaderVisible;
  }

  public setLoaderText(loaderText: string, isloaderVisible: boolean = true): void {
    this.loaderText = loaderText;
    this.isloaderVisible = isloaderVisible;
  }

}
