import { Component } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent {

  unauthorizedMessage: string = '';
  constructor() {
    this.unauthorizedMessage = 'You are not authorized to view this page!';
  }
}
